<template>
  <main class="background">
    <router-view />
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
.accent {
  background-color: #343840bf !important;
}

.accent-dark {
  background-color: #212428e6 !important;
}

.text-primary {
  color: #d10565;
}

.background {
  background-color: #1e2630;
  background-repeat: no-repeat;
  background-image: url("@/assets/waves.png");
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  .v-card {
    z-index: 1;
  }
}

.min-100 {
  min-width: 100%;
}

.v-overlay .v-snackbar__content {
  text-align: center;
  font-size: 18px;
}

.v-overlay-container .v-overlay .v-overlay__scrim {
  background: #000000;
}

.v-btn.bg-primary {
  transition: 0.4s;
  &:hover {
    background-color: rgb(var(--v-theme-secondary)) !important;
  }
}

.data-table {
  &.v-card {
    max-width: max-content;
    margin: auto;
    position: unset;
    background: rgb(33 36 41 / 90%);
    .v-data-table-rows-no-data td {
      background: none;
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
  }
  .v-table {
    width: auto;
    background: none;
    padding-top: 5px;
    tr {
      display: flex;
      td:first-child {
        padding-left: 20px;
      }
      td:last-child,
      th:last-child {
        padding-right: 20px;
      }
    }
    .v-data-table {
      &__td {
        width: 275px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.6;
        padding: 4px 5px 0 0;
        background: none;
        font-size: 14px;
        &.v-data-table__th {
          display: flex;
          padding-top: 0;
        }
        a {
          text-decoration: none;
          color: #fff;
          line-height: 22px;
        }
      }
      &__th:first-child {
        padding-left: 20px;
      }
    }

    .v-table__wrapper > table > tbody > tr > td {
      border-bottom: thin solid
        rgba(var(--v-border-color), var(--v-border-opacity));
    }
  }
  .v-field--variant-filled .v-field__overlay {
    background-color: unset;
  }
  .v-table__wrapper > table > tbody > tr > td {
    border-bottom: thin solid
      rgba(var(--v-border-color), var(--v-border-opacity));
  }

  .v-card-title {
    display: flex;
    gap: 30px;
    .v-input {
      height: 55px;
      &:first-child {
        max-width: 210px;
      }
    }
  }
}

.tooltip-comments.v-tooltip,
.tooltip-tags.v-tooltip,
.tooltip-parents.v-tooltip {
  position: relative;
  .v-overlay__content {
    padding: 10px 10px 10px 25px;
    background: #084b84;
    border-radius: 20px;
    position: absolute;
    transform: translate(0px, 0%);
    ul {
      li {
        list-style: auto;
        line-height: 18px;
        min-width: min-content;
        max-width: 330px;
        margin-bottom: 12px;
        padding-left: 5px;
        margin-left: 5px;
        color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.tooltip-parents.v-tooltip {
  .v-overlay__content {
    background: #d10565;
    transform: translate(-15px, 0%);
  }
}
.tooltip-parents__text,
.parent-column .v-data-table-header__content span {
  color: #d10565;
  cursor: help;
}

.comments-datatable,
.tags-datatable {
  background: #084b84;
  width: 25px;
  height: 25px;
  line-height: 19px;
  padding: 3px;
  border-radius: 50px;
  text-align: center;
  cursor: help;
}

.tags-datatable {
  background: #be7200;
}

.tooltip-tags.v-tooltip {
  .v-overlay__content {
    background: #393c40;
    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 330px;
      min-width: min-content;
      gap: 10px;
      li {
        list-style: none;
        line-height: 18px;
        min-width: min-content;
        max-width: 330px;
        color: #fff;
        border-radius: 40px;
        padding: 2px 10px;
        margin-bottom: 0;
        margin-left: 0;
        &:nth-child(4n + 1) {
          background: #907090;
        }
        &:nth-child(4n + 2) {
          background: #829070;
        }
        &:nth-child(4n + 3) {
          background: #707990;
        }
        &:nth-child(4n + 4) {
          background: #70908b;
        }
      }
    }
  }
}

/* Details Page CSS */

.details {
  .title {
    &-block {
      position: relative;
      width: 50%;
      margin: auto;
    }
    &-details {
      height: 50px;
      background-color: #d10565;
    }
  }
  .return {
    position: absolute;
    height: 60px;
    left: -50px;
  }
  .mdi-keyboard-return {
    font-size: 38px;
  }
  .data-table {
    max-width: 100%;
  }
  .details-content {
    background-color: #00000000;
    position: unset;
    .v-list-item {
      position: unset;
      &:first-child .v-list-item__content {
        margin-top: 0px;
      }
      &__content {
        outline: 1px solid grey;
        min-height: 70px;
        margin-top: 1px;
      }
    }
  }

  .v-card.data-table {
    background: none;
  }
  .data-table {
    .background-details {
      background: #1c202af2;
    }
    .details-content {
      .details-table-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .details-table-infos__edit {
        cursor: pointer;
      }
      .details-dates {
        &__element {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 150px;
        }
      }
    }
    thead tr th {
      height: 40px;
    }
  }
  .related-data-table {
    background-color: #23252acc;
  }
  .details-table-infos {
    &-content {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
    &__cell {
      max-width: 95%;
    }
  }
  .details-dates {
    gap: 25px;
    &__element {
      min-width: fit-content;
    }
  }

  .comment {
    &s {
      &-list {
        gap: 20px;
        flex-wrap: nowrap;
        overflow-x: auto;

        .comment-block {
          z-index: 0;
          min-width: max-content;
          height: min-content;

          .comment-text {
            max-width: 600px;
          }
        }
      }
    }
    &-date {
      color: #ffffff80;
      &__date {
        margin-right: 10px;
      }
    }
    &-actions {
      display: flex;
    }
    &-edit,
    &-delete {
      cursor: pointer;
    }
  }

  .businessunits {
    list-style: decimal inside;
  }
}
</style>
