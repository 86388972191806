<template>
  <v-card class="data-table">
    <v-card-title>
      <v-text-field
        class="min-100"
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        label="Rechercher..."
        single-line
        color="primary"
        @keyup.enter="getTrainingCourses"
        @click:append="getTrainingCourses"
        @click:clear="resetSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table-server
      :show-current-page="true"
      items-per-page-text="Nombre d'élements par page."
      :headers="headers"
      :page="currentPage"
      :items-length="totalTrainingCourses"
      :items="trainingcourses"
      no-data-text="Aucune donnée"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="options = $event"
    >
      <template v-slot:[`item.TXT_Liaison`]="{ item }">
        <template v-if="item.raw.TXT_Theme">
          <div class="linked-relation">
            <p>
              {{ item.raw.TXT_Theme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.TXT_Liaison_2`]="{ item }">
        <template v-if="item.raw.TXT_SubTheme">
          <div class="linked-relation">
            <p>
              {{ item.raw.TXT_SubTheme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.Édition`]="{ item }">
        <v-icon
          icon="mdi-pencil"
          size="x-large"
          @click="openDialog(item)"
        ></v-icon>
      </template>

      <template v-slot:[`item.alert`]="{ item }">
        <div
          class="data-table-warning"
          v-if="!item.raw.ID_D_Theme && !item.raw.ID_D_SubTheme"
        >
          <v-icon class="warning_theme_subthemes">mdi-comment-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Merci de sélectioner un Thème / Sous-Thème
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon class="warning_theme_subthemes_linked"
            >mdi-comment-check</v-icon
          >
        </div>
      </template>
    </v-data-table-server>

    <v-dialog
      v-if="itemToEdit"
      v-model="dialog[itemToEdit.ID_D_Training]"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Modifier le thème et le sous-thème
        </v-card-title>
        <v-card-text>
          <p>Thème</p>
          <v-select
            v-model="selectedThemes[itemToEdit.ID_D_Training]"
            :items="themes.map((theme) => theme.TXT_Theme)"
            :loading="isLoading"
            :disabled="isLoading"
            :value="itemToEdit.TXT_Theme"
            variant="outlined"
            @input="
              (value) => {
                selectedThemes['theme-' + index] = value;
              }
            "
          >
          </v-select>
          <p>Sous-thème</p>
          <v-select
            v-model="selectedSubThemes[itemToEdit.ID_D_Training]"
            :items="filteredSubThemes.map((subtheme) => subtheme.TXT_SubTheme)"
            :loading="isLoading"
            :disabled="isLoading || filteredSubThemes.length === 0"
            :value="value"
            variant="outlined"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialog[itemToEdit.ID_D_Training] = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" @click="updateItem()">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </v-card>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  trainingcourses_urls,
  themes_urls,
  subthemes_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuFirstPanelItem",
  components: {},
  props: {},
  data() {
    return {
      search: "",
      sortBy: [],
      themes: [],
      subthemes: [],
      trainingcourses: [],
      totalTrainingCourses: 0,
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      itemToEdit: null,
      dialogJustOpened: false,
      trainingcourseId: null,

      isLoading: "",

      selectedColumn: null,
      selectedThemes: {},
      selectedSubThemes: [],
      value: null,
      dialog: {},
      headers: [
        {
          title: "Code Contenu",
          value: "TXT_L2_NDContentCode",
          key: "codeContenu",
          sortable: false,
        },
        {
          title: "Drillster",
          value: "TXT_L2_LMSTitle",
          key: "Drillster",
          sortable: false,
        },
        {
          title: "Liaison Thème",
          value: "TXT_Liaison",
          key: "TXT_Liaison",
          sortable: false,
        },
        {
          title: "Liaison Sous-Thème",
          value: "TXT_Liaison_2",
          key: "TXT_Liaison_2",
          sortable: false,
        },
        {
          title: "Édition",
          value: "Édition",
          key: "Édition",
          sortable: false,
        },
        {
          title: "Attention",
          value: "alert",
          key: "alert",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getTrainingCourses();
      },
      deep: true,
    },

    selectedThemes: {
      handler: function (newVal) {
        const selectedTheme = newVal[this.itemToEdit.ID_D_Training];
        const themeObject = this.themes.find(
          (theme) => theme.TXT_Theme === selectedTheme
        );

        if (themeObject && themeObject.SubThemes) {
          this.filteredSubThemes = this.subthemes.filter((subtheme) =>
            themeObject.SubThemes.includes(subtheme.ID_D_SubTheme)
          );
        } else {
          this.filteredSubThemes = [];
        }

        if (!this.dialogJustOpened) {
          this.value = null; // Réinitialiser value à null
          this.selectedSubThemes[this.itemToEdit.ID_D_Training] = null; // Réinitialiser selectedSubThemes à null
        }

        this.dialogJustOpened = false; // Réinitialiser le flag
      },
      deep: true,
    },
  },
  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${trainingcourses_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTrainingCourses() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;

      let url = `${base_url}${trainingcourses_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalTrainingCourses = response.data.count;
        this.trainingcourses = response.data.results.results.map(
          (trainingcourse) => ({
            ...trainingcourse,
            DAT_Created: trainingcourse.DAT_Created
              ? this.formatDate(trainingcourse.DAT_Created)
              : "N/A",
            DAT_LastEdited: trainingcourse.DAT_LastEdited
              ? this.formatDate(trainingcourse.DAT_LastEdited)
              : "N/A",
          })
        );
      } catch (error) {
        console.error("Error fetching training courses:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        trainingcourses_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTrainingCourses();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getTrainingCourses();
    },

    openDialog(item) {
      this.dialogJustOpened = true;

      if (item.raw && item.raw.ID_D_Training !== undefined) {
        this.itemToEdit = item.raw;
        this.trainingcourseId = item.raw.ID_D_Training;

        this.dialog[this.itemToEdit.ID_D_Training] = true;
        this.selectedThemes[this.itemToEdit.ID_D_Training] = null; // ou une valeur par défaut
        this.selectedSubThemes[this.itemToEdit.ID_D_Training] = null; // ou une valeur par défaut
      } else {
        console.error("item or item.ID_D_Training is undefined", item);
      }
    },

    async getThemesSubThemes() {
      this.isLoading = true;
      const cacheVersion = await this.initializeCacheBusting();

      let urlThemes = `${base_url}${themes_urls.get_all}?page=${this.options.page}&page_size=10000&cacheVersion=${cacheVersion}`;
      let urlSubThemes = `${base_url}${subthemes_urls.get_all}?page=${this.options.page}&page_size=10000&cacheVersion=${cacheVersion}`;


      try {
        const [themesResponse, subthemesResponse] = await Promise.all([
          axios.get(urlThemes, header),
          axios.get(urlSubThemes, header),
        ]);

        // Suppose cache_version is available in the API response for themes and subthemes
        const newCacheVersion =
          themesResponse.data.results.cache_version ||
          subthemesResponse.data.results.cache_version;
        if (newCacheVersion !== cacheVersion) {
          this.updateCacheBusting(newCacheVersion);
          return this.getThemesSubThemes(); // Recurse to get latest data after updating cacheVersion
        }

        this.themes = themesResponse.data.results.results.map((theme) => ({
          ...theme,
        }));
        this.subthemes = subthemesResponse.data.results.results.map(
          (subtheme) => ({ ...subtheme })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async updateItem() {
      const themeId = this.themes.find(
        (theme) =>
          theme.TXT_Theme === this.selectedThemes[this.itemToEdit.ID_D_Training]
      ).ID_D_Theme;

      const subthemeId = this.subthemes.find(
        (subtheme) =>
          subtheme.TXT_SubTheme ===
          this.selectedSubThemes[this.itemToEdit.ID_D_Training]
      ).ID_D_SubTheme;

      const getUrl = `${base_url}${trainingcourses_urls.get_liaison(
        themeId,
        subthemeId
      )}`;

      let ID_BT_ThemeSubTheme;
      try {
        const getResponse = await axios.get(getUrl, header);
        ID_BT_ThemeSubTheme = getResponse.data[0].ID_BT_ThemeSubTheme;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la relation Thème/Sous-Thème:",
          error
        );
        return;
      }

      const trainingCourseId = this.trainingcourseId;
      const putUrl = `${base_url}${trainingcourses_urls.update(
        trainingCourseId
      )}`;

      const dataToUpdate = {
        ID_BT_ThemeSubTheme: ID_BT_ThemeSubTheme,
      };

      try {
        await axios.put(putUrl, dataToUpdate, header);
        this.dialog[this.itemToEdit.ID_D_Training] = false;
        const newCacheVersion = new Date().getTime().toString();
        localStorage.setItem("cacheVersion", newCacheVersion);
        this.snackbar = true;

        this.snackbarMessage = `Une nouvelle <strong>Liaison</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";

        this.getTrainingCourses();
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.snackbarMessage = `Une erreur s'est produite lors de la liaison <strong>Thème Sous-Thème</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },
  },

  created() {
    this.getTrainingCourses();
    this.getThemesSubThemes();
  },
};
</script>

<style lang="scss"></style>
