<template>
  <div class="details module">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Module</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <v-list-item class="w-100 pa-0" v-for="key in keyOrder1" :key="key">
              <div class="details-table-infos px-10 py-3">
                <div class="details-table-infos__cell">
                  <div :class="{ 'text-primary': key === 'SubThemes' }">
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>
                  <div class="details-table-infos-content">
                    <div
                      v-if="
                        key === 'SubThemes' &&
                        module[key] &&
                        Array.isArray(module[key])
                      "
                    >
                      {{
                        module[key]
                          .map(
                            (subtheme) => subtheme.ID_D_SubTheme__TXT_SubTheme
                          )
                          .join(" / ")
                      }}
                    </div>
                    <template v-else>
                      {{ module[key] }}
                    </template>
                  </div>
                </div>

                <div
                  v-if="key !== 'SubThemes'"
                  class="details-table-infos__edit"
                  @click="openUpdateDialog(key)"
                >
                  <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                </div>
              </div>
            </v-list-item>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsModule"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>

                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>

            <div class="px-10 py-3 mt-8 d-flex align-center">
              <h2 class="text-h6 font-weight-bold">Questions associées :</h2>
              <v-btn
                icon
                class="details-table-infos__edit ml-2"
                @click="openUpdateDialog('Questions')"
              >
                <v-icon icon="mdi-pencil" size="x-large"></v-icon>
              </v-btn>
            </div>

            <v-data-table
              :headers="QuestionsHeaders"
              :items="module.Questions"
              class="w-100 related-data-table mx-10"
              color="table"
            >
              <template v-slot:[`item.TXT_Question`]="{ item }">
                <router-link :to="`/questions/${item.raw.ID_D_Question}`">{{
                  item.raw.TXT_Question
                }}</router-link>
              </template>
            </v-data-table>

            <div
              class="d-flex w-100 mt-6 px-10 align-center justify-space-between"
            >
              <div class="details-dates d-flex">
                <div
                  class="w-100 pa-0 details-dates__element"
                  v-for="key in keyOrder2"
                  :key="key"
                >
                  <div class="element">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div>
                      <!-- Utilisation de la méthode formatDate si la clé est une date -->
                      {{
                        key === "DAT_LastEdited" || key === "DAT_Created"
                          ? formatDate(module[key])
                          : module[key]
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <v-btn color="primary" @click="openDeleteModuleDialog(module)"
                >Supprimer le module</v-btn
              >
            </div>
          </v-list>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="
              fieldToUpdate !== 'SubThemes' &&
              fieldToUpdate !== 'Questions' &&
              fieldToUpdate !== 'TXT_Description' &&
              fieldToUpdate !== 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
          <v-textarea
            v-else-if="
              fieldToUpdate === 'TXT_Description' ||
              fieldToUpdate === 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            color="primary"
          ></v-textarea>

          <v-select
            v-if="fieldToUpdate === 'SubThemes'"
            v-model="newFieldValue"
            :items="SubThemes.map((subtheme) => subtheme.TXT_SubTheme)"
            label="Sélectionner des sous-thèmes"
            multiple
          ></v-select>
          <v-select
            v-if="fieldToUpdate === 'Questions'"
            v-model="newFieldValue"
            :items="formattedQuestions"
            item-value="value"
            item-text="text"
            label="Sélectionner des questions"
            multiple
            :loading="isLoading"
          >
            <template #prepend-item>
              <v-text-field
                v-model="search"
                label="Rechercher dans la liste..."
                variant="underlined"
                single-line
                color="primary"
                class="px-6"
              ></v-text-field>
            </template>
            <template #selection="{ index, item, selected }">
              <v-chip
                v-if="index === 0"
                :key="`selected-${item.value}`"
                :input-value="selected"
              >
                {{ newFieldValue.length }} sélectionnés
              </v-chip>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteModuleDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer ce module ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteModuleDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteModule">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  modules_urls,
  comments_urls,
  user_urls,
  subthemes_urls,
  questions_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ModuleSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      module: {
        comments: [],
      },
      keyTextMap: {
        TXT_Module: "Module",
        TXT_Description: "Description",
        TXT_LearningObjectif: "Objectif pédagogique",
        TXT_Ref: "Ref",
        DAT_Created: "Création",
        TXT_LastEditedBy: "Modifié par",
        DAT_LastEdited: "Dernière modification",
        SubThemes: "Sous-Thèmes associés",
        Questions: "Questions associées",
      },
      keyOrder1: [
        "TXT_Module",
        "TXT_Description",
        "TXT_LearningObjectif",
        "TXT_Ref",
        "SubThemes",
      ],
      keyOrder2: ["DAT_Created", "DAT_LastEdited", "TXT_LastEditedBy"],

      QuestionsHeaders: [
        {
          title: "Questions",
          value: "TXT_Question",
          key: "TXT_Question",
        },
        // {
        //   title: "Bonne réponse",
        //   value: "TXT_RightAnswer",
        //   key: "TXT_RightAnswer",
        // },
      ],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      moduleToDelete: null,
      deleteModuleDialog: false,

      SubThemes: [],
      Questions: [],
      search: "",
      isLoading: false,

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    openDeleteModuleDialog(module) {
      this.moduleToDelete = module;
      this.deleteModuleDialog = true;
    },

    deleteModule() {
      const moduleId = this.$route.params.id;
      const url = `${base_url}${modules_urls.delete(moduleId)}`;
      if (this.moduleToDelete) {
        axios
          .delete(url, header)
          .then(() => {
            this.snackbarMessage = `Le Module a été supprimé.`;
            this.snackbar = true;

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.$router.push("/modules");
          })
          .catch((error) => {
            console.error("Erreur lors de la suppression du module:", error);

            this.snackbarMessage = `Erreur lors de la suppression du <strong>Module</strong>`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
        this.deleteModuleDialog = false;
      }
    },

    getSubThemes() {
      const url = `${base_url}${subthemes_urls.get_all}`;
      axios
        .get(url, header)
        .then((response) => {
          this.SubThemes = response.data;
        })
        .catch((error) => {
          console.error("Error fetching SubThemes:", error);
        });
    },
    getQuestions() {
      const url = `${base_url}${questions_urls.get_all}?page=1&page_size=100000`;
      this.isLoading = true;

      axios
        .get(url, header)
        .then((response) => {
          this.Questions = response.data.results.results;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching Questions:", error);
        });
    },

    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getModuleDetails() {
      const moduleId = this.$route.params.id;
      const url = `${base_url}${modules_urls.get_details(moduleId)}`;
      const commentsUrl = `${base_url}${comments_urls.module_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.module = {
            ...response.data,
          };
          if (this.module.TXT_LastEditedBy) {
            return this.getUserDetails(this.module.TXT_LastEditedBy);
          } else {
            return Promise.resolve(null);
          }
        })
        .then((userResponse) => {
          // Replace the user ID with username
          if (userResponse) {
            this.module.TXT_LastEditedBy = userResponse.data.username;
          }
          return axios.get(commentsUrl, header);
          // Fetch comments
        })
        .then((commentsResponse) => {
          this.module.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_Module === parseInt(moduleId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.module.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.module.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching module details:", error);
        });
    },

    openAddCommentDialog() {
      this.newComment = "";
      this.addCommentDialog = true;
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "SubThemes") {
        this.newFieldValue = this.module[key].map(
          (subtheme) => subtheme.ID_D_SubTheme__TXT_SubTheme
        );
      } else if (key === "Questions") {
        this.getQuestions();
        this.newFieldValue = this.module[key].map(
          (question) => question.ID_D_Question
        );
      } else {
        this.newFieldValue = this.module[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (
        this.fieldToUpdate === "TXT_Description" ||
        this.fieldToUpdate === "TXT_LearningObjectif" ||
        (this.newFieldValue !== undefined &&
          this.newFieldValue !== null &&
          this.newFieldValue !== "")
      ) {
        if (this.fieldToUpdate === "SubThemes") {
          const newSubThemes = this.SubThemes.filter((subtheme) =>
            this.newFieldValue.includes(subtheme.ID_D_SubTheme__TXT_SubTheme)
          );
          this.updateModule(this.fieldToUpdate, newSubThemes);
        } else if (this.fieldToUpdate === "Questions") {
          const newQuestions = this.Questions.filter((question) =>
            this.newFieldValue.includes(question.ID_D_Question)
          );
          this.updateModule(this.fieldToUpdate, newQuestions);
        } else if (this.fieldToUpdate === "TXT_Comment") {
          this.updateComment(this.currentCommentID, this.newFieldValue);
        } else {
          this.updateModule(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.module_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.module.comments.findIndex(
            (comment) => comment.ID_FS_CommentsModule === commentId
          );
          this.module.comments[commentIndex].TXT_Comment = newComment;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateModule(field, newValue) {
      // Update the module on the server
      const moduleId = this.$route.params.id;
      const url = `${base_url}${modules_urls.update(moduleId)}`;

      const updatedData = {
        TXT_Module: this.module.TXT_Module,
        TXT_Description: this.module.TXT_Description,
        TXT_LearningObjectif: this.module.TXT_LearningObjectif,
        DAT_Created: this.module.DAT_Created,
        [field]: newValue, // Override the field to update with the new value
        DAT_LastEdited: new Date().toISOString(), // Current date
        TXT_CreatedBy: this.module.TXT_CreatedBy, // Created by field should not change
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
      };

      if (field !== "Questions") {
        updatedData.Questions = this.module.Questions.map(
          (question) => question.ID_D_Question
        );
      }

      if (field === "Questions") {
        updatedData.Questions = this.newFieldValue;
      }

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.module = response.data;

          this.getModuleDetails();

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsModule;
    },

    addComment() {
      const url = `${base_url}${comments_urls.module_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_Module: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getModuleDetails(); // Fetch subtheme details again to update the comments list

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbar = true;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.addCommentDialog = false;
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_module(
          this.commentToDelete.ID_FS_CommentsModule
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getModuleDetails();

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  },

  computed: {
    reversedComments() {
      return [...(this.module.comments || [])].reverse();
    },
    formattedQuestions() {
      if (!this.search) {
        return this.Questions.map((question) => ({
          title:
            question.TXT_Question.length > 75
              ? question.TXT_Question.substring(0, 75) + "..."
              : question.TXT_Question,
          value: question.ID_D_Question,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.Questions.filter((question) => {
        const questionLowerCase = question.TXT_Question.toLowerCase();
        return searchTerms.every((term) => questionLowerCase.includes(term));
      }).map((question) => ({
        title:
          question.TXT_Question.length > 75
            ? question.TXT_Question.substring(0, 75) + "..."
            : question.TXT_Question,
        value: question.ID_D_Question,
      }));
    },
  },

  created() {
    this.getModuleDetails();
    this.getSubThemes();
  },

  mounted() {
    document.title = "Module - Détails";
  },
};
</script>

<style lang="scss">
.details.module .data-table .v-table .v-data-table__td {
  width: 100%;
}
</style>
