<template>
  <div class="details">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Sous-Thème</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <v-list-item class="w-100 pa-0" v-for="key in keyOrder1" :key="key">
              <div class="details-table-infos px-10 py-3">
                <div class="details-table-infos__cell">
                  <div :class="{ 'text-primary': key === 'themes' }">
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>
                  <div class="details-table-infos-content">
                    <div
                      v-if="
                        key === 'themes' &&
                        subtheme[key] &&
                        Array.isArray(subtheme[key])
                      "
                    >
                      {{
                        subtheme[key]
                          .map((theme) => theme.ID_D_Theme__TXT_Theme)
                          .join(" / ")
                      }}
                    </div>
                    <template v-else>
                      {{ subtheme[key] }}
                    </template>
                  </div>
                </div>
                <div
                  v-if="key !== 'themes'"
                  class="details-table-infos__edit"
                  @click="openUpdateDialog(key)"
                >
                  <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                </div>
              </div>
            </v-list-item>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsSubTheme"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>

            <div class="px-10 py-3 mt-8 d-flex align-center">
              <h2 class="text-h6 font-weight-bold">Modules associées :</h2>
              <v-btn
                icon
                class="details-table-infos__edit ml-2"
                @click="openUpdateDialog('Modules')"
              >
                <v-icon icon="mdi-pencil" size="x-large"></v-icon>
              </v-btn>
            </div>
            <v-data-table
              :headers="ModulesHeaders"
              :items="subtheme.Modules"
              class="w-100 related-data-table mx-10"
              color="table"
            >
              <template v-slot:[`item.TXT_Module`]="{ item }">
                <router-link :to="`/modules/${item.raw.ID_D_Module}`">{{
                  item.raw.TXT_Module
                }}</router-link>
              </template>
            </v-data-table>

            <div
              class="d-flex w-100 mt-6 px-10 align-center justify-space-between"
            >
              <div class="details-dates d-flex">
                <div
                  class="w-100 pa-0 details-dates__element"
                  v-for="key in keyOrder2"
                  :key="key"
                >
                  <div class="element">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div>
                      <!-- Utilisation de la méthode formatDate si la clé est une date -->
                      {{
                        key === "DAT_LastEdited" || key === "DAT_Created"
                          ? formatDate(subtheme[key])
                          : subtheme[key]
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <v-btn color="primary" @click="openDeleteSubThemeDialog(subtheme)"
                >Supprimer le sous-thème</v-btn
              >
            </div>
          </v-list>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="
              fieldToUpdate !== 'themes' &&
              fieldToUpdate !== 'Modules' &&
              fieldToUpdate !== 'TXT_Description' &&
              fieldToUpdate !== 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>

          <v-textarea
            v-else-if="
              fieldToUpdate === 'TXT_Description' ||
              fieldToUpdate === 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-select
          v-if="fieldToUpdate === 'themes'"
          v-model="newFieldValue"
          :items="Themes.map((theme) => theme.TXT_Theme)"
          label="Sélectionner des thèmes"
          multiple
        ></v-select>

        <v-select
          v-if="fieldToUpdate === 'Modules'"
          v-model="newFieldValue"
          :items="formattedModules"
          item-value="value"
          item-text="text"
          label="Sélectionner des modules"
          multiple
          :loading="isLoading"
        >
          <template #prepend-item>
            <v-text-field
              v-model="search"
              label="Rechercher dans la liste..."
              variant="underlined"
              single-line
              color="primary"
              class="px-6"
            ></v-text-field>
          </template>
          <template #selection="{ index, item, selected }">
            <v-chip
              v-if="index === 0"
              :key="`selected-${item.value}`"
              :input-value="selected"
            >
              {{ newFieldValue.length }} sélectionnés
            </v-chip>
          </template>
        </v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteSubThemeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer ce sous-thème ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteSubThemeDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteSubTheme">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  subthemes_urls,
  comments_urls,
  user_urls,
  themes_urls,
  modules_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "SubThemeSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      subtheme: {
        comments: [],
      },
      keyTextMap: {
        TXT_SubTheme: "Sous-thème",
        TXT_Description: "Description",
        TXT_LearningObjectif: "Objectif pédagogique",
        TXT_Ref: "Ref",
        DAT_Created: "Création",
        TXT_LastEditedBy: "Modifié par",
        DAT_LastEdited: "Dernière modification",
        themes: "Thèmes associés",
        Modules: "Modules associés",
      },
      keyOrder1: [
        "TXT_SubTheme",
        "TXT_Description",
        "TXT_LearningObjectif",
        "TXT_Ref",
        "themes",
      ],
      keyOrder2: ["DAT_Created", "DAT_LastEdited", "TXT_LastEditedBy"],

      ModulesHeaders: [
        {
          title: "Module",
          value: "TXT_Module",
          key: "TXT_Module",
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
        },
        {
          title: "Objectif pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
        },
      ],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      subthemeToDelete: null,
      deleteSubThemeDialog: false,

      Themes: [],
      Modules: [],
      search: "",
      isLoading: false,

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    openDeleteSubThemeDialog(subtheme) {
      this.subthemeToDelete = subtheme;
      this.deleteSubThemeDialog = true;
    },

    deleteSubTheme() {
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.delete(subthemeId)}`;
      if (this.subthemeToDelete) {
        axios
          .delete(url, header)
          .then(() => {
            this.snackbarMessage = `Le Sous-Thème a été supprimé.`;
            this.snackbar = true;

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.$router.push("/subthemes");
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la suppression du sous-thème:",
              error
            );

            this.snackbarMessage = `Erreur lors de la suppression du <strong>Sous-Thème</strong>`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
        this.deleteSubThemeDialog = false;
      }
    },

    getThemes() {
      const url = `${base_url}${themes_urls.get_all}`;
      axios
        .get(url, header)
        .then((response) => {
          this.Themes = response.data;
        })
        .catch((error) => {
          console.error("Error fetching themes:", error);
        });
    },
    getModules() {
      const url = `${base_url}${modules_urls.get_all}?page=1&page_size=100000`;
      this.isLoading = true;

      axios
        .get(url, header)
        .then((response) => {
          this.Modules = response.data.results.results;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching Modules:", error);
        });
    },

    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getSubThemeDetails() {
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.get_details(subthemeId)}`;
      const commentsUrl = `${base_url}${comments_urls.subtheme_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.subtheme = {
            ...response.data,
          };
          if (this.subtheme.TXT_LastEditedBy) {
            return this.getUserDetails(this.subtheme.TXT_LastEditedBy);
          } else {
            return Promise.resolve(null);
          }
        })
        .then((userResponse) => {
          if (userResponse) {
            this.subtheme.TXT_LastEditedBy = userResponse.data.username;
          }
          return axios.get(commentsUrl, header);
        })

        .then((commentsResponse) => {
          this.subtheme.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_SubTheme === parseInt(subthemeId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.subtheme.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.subtheme.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching subtheme details:", error);
        });
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "themes") {
        this.newFieldValue = this.subtheme[key].map(
          (theme) => theme.ID_D_Theme__TXT_Theme
        );
      } else if (key === "Modules") {
        this.getModules();
        this.newFieldValue = this.subtheme[key].map(
          (module) => module.ID_D_Module
        );
      } else {
        this.newFieldValue = this.subtheme[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (
        this.fieldToUpdate === "TXT_Description" ||
        this.fieldToUpdate === "TXT_LearningObjectif" ||
        (this.newFieldValue !== undefined &&
          this.newFieldValue !== null &&
          this.newFieldValue !== "")
      ) {
        if (this.fieldToUpdate === "themes") {
          const newThemes = this.Themes.filter((theme) =>
            this.newFieldValue.includes(theme.ID_D_Theme__TXT_Theme)
          );
          this.updateSubTheme(this.fieldToUpdate, newThemes);
        } else if (this.fieldToUpdate === "Modules") {
          const newModules = this.Modules.filter((module) =>
            this.newFieldValue.includes(module.ID_D_Module)
          );
          this.updateSubTheme(this.fieldToUpdate, newModules);
        } else if (this.fieldToUpdate === "TXT_Comment") {
          this.updateComment(this.currentCommentID, this.newFieldValue);
        } else {
          this.updateSubTheme(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.subtheme_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.subtheme.comments.findIndex(
            (comment) => comment.ID_FS_CommentsSubTheme === commentId
          );
          this.subtheme.comments[commentIndex].TXT_Comment = newComment;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateSubTheme(field, newValue) {
      // Update the subtheme on the server
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.update(subthemeId)}`;

      const updatedData = {
        TXT_SubTheme: this.subtheme.TXT_SubTheme,
        TXT_Description: this.subtheme.TXT_Description,
        TXT_LearningObjectif: this.subtheme.TXT_LearningObjectif,
        TXT_Ref: this.subtheme.TXT_Ref,
        DAT_Created: this.subtheme.DAT_Created,
        [field]: newValue, // Override the field to update with the new value
        DAT_LastEdited: new Date().toISOString(), // Current date
        TXT_CreatedBy: this.subtheme.TXT_CreatedBy, // Created by field should not change
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
      };

      if (field !== "Modules") {
        updatedData.Modules = this.subtheme.Modules.map(
          (module) => module.ID_D_Module
        );
      }

      if (field === "Modules") {
        updatedData.Modules = this.newFieldValue;
      }

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.subtheme = response.data;

          this.getSubThemeDetails();

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsSubTheme; // Stocker l'ID du commentaire actuel
    },

    openAddCommentDialog() {
      this.newComment = ""; // Reset the new comment field
      this.addCommentDialog = true;
    },

    addComment() {
      const url = `${base_url}${comments_urls.subtheme_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_SubTheme: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getSubThemeDetails(); // Fetch subtheme details again to update the comments list

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbar = true;

          this.addCommentDialog = false;
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_subtheme(
          this.commentToDelete.ID_FS_CommentsSubTheme
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getSubThemeDetails();

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  },

  computed: {
    reversedComments() {
      return [...(this.subtheme.comments || [])].reverse();
    },
    formattedModules() {
      if (!this.search) {
        return this.Modules.map((module) => ({
          title:
            module.TXT_Module.length > 75
              ? module.TXT_Module.substring(0, 75) + "..."
              : module.TXT_Module,
          value: module.ID_D_Module,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.Modules.filter((module) => {
        const moduleLowerCase = module.TXT_Module.toLowerCase();
        return searchTerms.every((term) => moduleLowerCase.includes(term));
      }).map((module) => ({
        title:
          module.TXT_Module.length > 75
            ? module.TXT_Module.substring(0, 75) + "..."
            : module.TXT_Module,
        value: module.ID_D_Module,
      }));
    },
  },

  created() {
    this.getSubThemeDetails();
    this.getThemes();
  },

  mounted() {
    document.title = "Sous-Thème - Détails";
  },
};
</script>

<style lang="scss">
.details .data-table .v-table .v-data-table__td {
  width: 33%;
}
</style>
