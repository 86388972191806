<template>
  <div class="password-change details">
    <div class="title-block my-0">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 text-center mb-16">Changement de mot de passe</h1>
    </div>
    <form @submit.prevent="changePassword" class="mt-n8">
      <v-container fluid style="max-width: 1440px">
        <v-row
          class="justify-center flex-column align-center"
          style="transform: scale(0.85)"
        >
          <!-- OLD PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg mb-10 elevation-1">
            <v-text-field
              v-model="oldPassword"
              :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOld ? 'text' : 'password'"
              label="Ancien mot de passe"
              @click:append="showOld = !showOld"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <!-- NEW PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg mb-10 elevation-1">
            <v-text-field
              v-model="newPassword"
              :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNew ? 'text' : 'password'"
              label="Nouveau mot de passe"
              hint="Minimum 8 caractères"
              @click:append="showNew = !showNew"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <!-- CONFIRM PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg elevation-1">
            <v-text-field
              v-model="confirmPassword"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirm ? 'text' : 'password'"
              label="Confirmer le mot de passe"
              @click:append="showConfirm = !showConfirm"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <!-- SUBMIT -->
          <v-col class="px-7 mt-7 d-flex justify-center">
            <v-btn
              type="submit"
              :disabled="!valid"
              color="primary"
              class="d-flex pa-7 px-11 primary rounded-lg"
              :loading="loading"
            >
              Changer le mot de passe
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, user_urls, header } from "@/API/config.js";

export default {
  name: "PasswordNew",
  data: () => ({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
    showOld: false,
    showNew: false,
    showConfirm: false,
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "success",
    loading: false,
    valid: true,
  }),
  methods: {
    async changePassword() {
      this.loading = true;
      this.error = "";

      // Vérifie si les mots de passe correspondent
      if (this.newPassword !== this.confirmPassword) {
        this.error = "Les mots de passe ne correspondent pas.";
        this.snackbarMessage = this.error;
        this.snackbar = true;
        this.snackbarColor = "error";
        this.loading = false;
        return;
      }

      // Vérifie si l'ancien mot de passe est différent du nouveau
      if (this.oldPassword === this.newPassword) {
        this.error = "Le nouveau mot de passe doit être différent de l'ancien.";
        this.snackbarMessage = this.error;
        this.snackbar = true;
        this.snackbarColor = "error";
        this.loading = false;
        return;
      }

      const payload = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };

      const url = `${base_url}${user_urls.change_password}`;
      try {
        await axios.post(url, payload, header);
        this.snackbarMessage = "Mot de passe changé avec succès!";
        this.snackbar = true;
        this.snackbarColor = "success";

        // Redirection après changement de mot de passe réussi
        setTimeout(() => this.$router.push("/account"), 2000);
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.error) {
            this.error = error.response.data.error;
          } else {
            this.error = Object.values(error.response.data).flat().join(" ");
          }
        } else {
          this.error =
            "Une erreur est survenue lors du changement de mot de passe.";
        }

        this.snackbarMessage = this.error;
        this.snackbar = true;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    document.title = "Changement Mot de Passe";
  },
};
</script>
