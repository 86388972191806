<template>
  <v-card class="data-table">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher..."
        single-line
        class="min-100"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :sort-by="sortBy"
      :headers="headers"
      :items="filteredTestDrills"
      :custom-filter="selectedColumnFilter"
    >
      <template v-slot:[`item.TXT_Liaison`]="{ item }">
        <template v-if="item.raw.TXT_Theme">
          <div class="linked-relation">
            <p>
              {{ item.raw.TXT_Theme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.TXT_Liaison_2`]="{ item }">
        <template v-if="item.raw.TXT_SubTheme">
          <div class="linked-relation">
            <p>
              {{ item.raw.TXT_SubTheme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.Édition`]="{ item }">
        <v-icon
          icon="mdi-pencil"
          size="x-large"
          @click="openDialog(item)"
        ></v-icon>
      </template>

      <template v-slot:[`item.alert`]="{ item }">
        <div
          class="data-table-warning"
          v-if="!item.raw.ID_D_Theme && !item.raw.ID_D_SubTheme"
        >
          <v-icon class="warning_theme_subthemes">mdi-comment-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Merci de sélectioner un Thème / Sous-Thème
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon class="warning_theme_subthemes_linked"
            >mdi-comment-check</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-if="itemToEdit"
      v-model="dialog[itemToEdit.ID_D_Training]"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Modifier le thème et le sous-thème
        </v-card-title>
        <v-card-text>
          <p>Thème</p>
          <v-select
            v-model="selectedThemes[itemToEdit.ID_D_Training]"
            :items="themes.map((theme) => theme.TXT_Theme)"
            :loading="isLoading"
            :disabled="isLoading"
            :value="itemToEdit.TXT_Theme"
            variant="outlined"
            @input="
              (value) => {
                selectedThemes['theme-' + index] = value;
              }
            "
          >
          </v-select>
          <p>Sous-thème</p>
          <v-select
            v-model="selectedSubThemes[itemToEdit.ID_D_Training]"
            :items="filteredSubThemes.map((subtheme) => subtheme.TXT_SubTheme)"
            :loading="isLoading"
            :disabled="isLoading || filteredSubThemes.length === 0"
            :value="value"
            variant="outlined"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialog[itemToEdit.ID_D_Training] = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" @click="updateItem()">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </v-card>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  testdrills_urls,
  themes_urls,
  subthemes_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuThirdPanelItem",
  components: {},
  props: {},
  data() {
    return {
      search: "",
      sortBy: [],
      themes: [],
      subthemes: [],
      testdrills: [],
      filteredSubthemes: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      itemToEdit: null,
      dialogJustOpened: false,
      testdrillId: null,

      isLoading: "",

      selectedColumn: null,
      selectedThemes: {},
      selectedSubThemes: [],
      value: null,
      dialog: {},
      headers: [
        {
          title: "Drill",
          value: "TXT_L3_LMSTitle",
          key: "TXT_L3_LMSTitle",
        },
        {
          title: "Liaison Thème",
          value: "TXT_Liaison",
          key: "TXT_Liaison",
        },
        {
          title: "Liaison Sous-Thème",
          value: "TXT_Liaison_2",
          key: "TXT_Liaison_2",
        },
        {
          title: "Édition",
          value: "Édition",
          key: "Édition",
        },
        {
          title: "Attention",
          value: "alert",
          key: "alert",
        },
      ],
    };
  },

  computed: {
    filteredTestDrills() {
      let testdrillsArray = Object.values(this.testdrills);

      if (this.search === "") {
        return testdrillsArray;
      }

      if (this.selectedColumn === null) {
        return testdrillsArray.filter((item) =>
          Object.values(item).some((value) =>
            this.selectedColumnFilter(value, this.search)
          )
        );
      }

      return testdrillsArray.filter((item) =>
        this.selectedColumnFilter(item[this.selectedColumn], this.search)
      );
    },
  },
  watch: {
    "itemToEdit.TXT_SubTheme": function (newVal) {
      this.value = newVal;
    },
    selectedThemes: {
      handler: function (newVal) {
        const selectedTheme = newVal[this.itemToEdit.ID_D_Training];
        const themeObject = this.themes.find(
          (theme) => theme.TXT_Theme === selectedTheme
        );

        if (themeObject && themeObject.SubThemes) {
          this.filteredSubThemes = this.subthemes.filter((subtheme) =>
            themeObject.SubThemes.includes(subtheme.ID_D_SubTheme)
          );
        } else {
          this.filteredSubThemes = [];
        }

        if (!this.dialogJustOpened) {
          this.value = null; // Réinitialiser value à null
          this.selectedSubThemes[this.itemToEdit.ID_D_Training] = null; // Réinitialiser selectedSubThemes à null
        }

        this.dialogJustOpened = false; // Réinitialiser le flag
      },
      deep: true,
    },
  },
  methods: {
    async getTestDrills() {
      const url = `${base_url}${testdrills_urls.get_all_fake}?page=1&page_size=1000`;
      try {
        const response = await axios.get(url, header);
        this.testdrills = response.data.map((testdrill) => ({
          ...testdrill,
          filteredSubthemes: [],
        }));
      } catch (error) {
        console.error("Error fetching drills:", error);
      }
    },

    openDialog(item) {
      this.dialogJustOpened = true;

      if (item.raw && item.raw.ID_D_Training !== undefined) {
        this.itemToEdit = item.raw;
        this.testdrillId = item.raw.ID_D_Training;

        this.dialog[this.itemToEdit.ID_D_Training] = true;
        this.selectedThemes[this.itemToEdit.ID_D_Training] = null; // ou une valeur par défaut
        this.selectedSubThemes[this.itemToEdit.ID_D_Training] = null; // ou une valeur par défaut
      } else {
        console.error("item or item.ID_D_Training is undefined", item);
      }
    },

    async getThemesSubThemes() {
      try {
        this.isLoading = true;

        const urlThemes = `${base_url}${themes_urls.get_all}?page=1&page_size=1000`;
        const urlSubThemes = `${base_url}${subthemes_urls.get_all}?page=1&page_size=1000`;

        const [themesResponse, subthemesResponse] = await Promise.all([
          axios.get(urlThemes, header),
          axios.get(urlSubThemes, header),
        ]);

        this.themes = themesResponse.data.results.results.map((theme) => ({
          ...theme,
        }));

        this.subthemes = subthemesResponse.data.results.results.map(
          (subtheme) => ({
            ...subtheme,
          })
        );

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },

    async updateItem() {
      const themeId = this.themes.find(
        (theme) =>
          theme.TXT_Theme === this.selectedThemes[this.itemToEdit.ID_D_Training]
      ).ID_D_Theme;

      const subthemeId = this.subthemes.find(
        (subtheme) =>
          subtheme.TXT_SubTheme ===
          this.selectedSubThemes[this.itemToEdit.ID_D_Training]
      ).ID_D_SubTheme;

      const getUrl = `${base_url}${testdrills_urls.get_liaison(
        themeId,
        subthemeId
      )}`;

      let ID_BT_ThemeSubTheme;
      try {
        const getResponse = await axios.get(getUrl, header);
        ID_BT_ThemeSubTheme = getResponse.data[0].ID_BT_ThemeSubTheme;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la relation Thème/Sous-Thème:",
          error
        );
        return;
      }

      const trainingCourseId = this.testdrillId;
      const putUrl = `${base_url}${testdrills_urls.update(trainingCourseId)}`;

      const dataToUpdate = {
        ID_BT_ThemeSubTheme: ID_BT_ThemeSubTheme,
      };

      try {
        await axios.put(putUrl, dataToUpdate, header);
        this.dialog[this.itemToEdit.ID_D_Training] = false;
        this.snackbar = true;

        this.snackbarMessage = `Une nouvelle <strong>Liaison</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";

        this.getTestDrills();
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.snackbarMessage = `Une erreur s'est produite lors de la liaison <strong>Thème Sous-Thème</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    selectedColumnFilter(value, queryText) {
      if (
        value === undefined ||
        queryText === null ||
        typeof value !== "string"
      ) {
        return false;
      }
      const valueToSearch = value.toString().toLocaleLowerCase();
      const queryWords = queryText
        .toLocaleLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return queryWords.every((word) => valueToSearch.indexOf(word) !== -1);
    },
  },

  created() {
    this.getTestDrills();
    this.getThemesSubThemes();
  },
};
</script>

<style lang="scss"></style>
