<template>
  <div class="details trainingblocsubscriptions_actions">
    <h2 class="px-10 py-3 mt-8 text-h6 font-weight-bold">
      Apprenants associés :
    </h2>
    <v-data-table
      :headers="headers"
      :items="traineesData"
      :loading="loading"
      class="w-100 related-data-table mx-10"
      color="table"
    >
      <template v-slot:[`item.TXT_SubTheme`]="{ item }">
        <router-link :to="`/trainees/${item.raw.ID_D_Trainees}`">{{
          item.raw.TXT_Trainees
        }}</router-link>
      </template>

      <template v-slot:[`item.TXT_SubscriptionStatus`]="{ item }">
        <v-chip :color="getStatusColor(item.raw.TXT_SubscriptionStatus)">
          {{ item.raw.TXT_SubscriptionStatus }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="actions">
          <v-btn :disabled="item.raw.BIT_Cancelled">
            <TrainingBlocExtentSubscriptionButton
              v-if="item"
              :item="item"
              :ActionAddTitle="ActionAddTitle"
              @selected-end-date-changed="handleSelectedEndDateChanged"
              @exam-date-changed="handleExamDateChanged"
              @refresh-training-subscriptions="getTrainingblocsubscriptions"
            />
          </v-btn>
          <v-btn :disabled="item.raw.BIT_Cancelled">
            <TrainingBlocPauseSubscriptionButton
              v-if="item"
              :item="item"
              :trainees-data="traineesData"
              :ActionPauseTitle="ActionPauseTitle"
              @selected-begin-pause="handleSelectedBeginPause"
              @selected-resume-date="handleSelectedResumeDate"
              @selected-end-date-changed="handleSelectedEndDateChanged"
              @exam-date-changed="handleExamDateChanged"
              @refresh-training-subscriptions="getTrainingblocsubscriptions"
            />
          </v-btn>
          <v-btn :disabled="item.raw.BIT_Cancelled">
            <TrainingBlocStopSubscriptionButton
              v-if="item"
              :item="item"
              :trainees-data="traineesData"
              :ActionDeleteTitle="ActionDeleteTitle"
              @selected-end-date-changed="handleSelectedEndDateChanged"
              @exam-date-changed="handleExamDateChanged"
              @refresh-training-subscriptions="getTrainingblocsubscriptions"
            />
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import { useChipStatusColor } from "@/reusables/chipStatusColor.js";
import TrainingBlocExtentSubscriptionButton from "./TrainingBlocExtentSubscriptionButton.vue";
import TrainingBlocPauseSubscriptionButton from "./TrainingBlocPauseSubscriptionButton.vue";
import TrainingBlocStopSubscriptionButton from "./TrainingBlocStopSubscriptionButton.vue";
import axios from "axios";
import {
  base_url,
  trainingblocsubscriptions_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "trainingblocsubscriptions_actions",
  components: {
    TrainingBlocExtentSubscriptionButton,
    TrainingBlocPauseSubscriptionButton,
    TrainingBlocStopSubscriptionButton,
  },

  data() {
    return {
      trainingbloc: [],
      headers: [
        [
          {
            title: "Nom",
            value: "TXT_LastName",
            key: "TXT_LastName",
            sortable: false,
          },
          {
            title: "Prénom",
            value: "TXT_FirstName",
            key: "TXT_FirstName",
            sortable: false,
          },
          {
            title: "Email",
            value: "TXT_Email",
            key: "TXT_Email",
            sortable: false,
          },
          {
            title: "Promotion",
            value: "TXT_LMSTrainingBlocType",
            key: "TXT_LMSTrainingBlocType",
            sortable: false,
          },
          {
            title: "Date De Début",
            value: "DAT_SubscriptionStart",
            key: "DAT_SubscriptionStart",
            sortable: false,
          },
          {
            title: "Date De Fin",
            value: "DAT_SubscriptionEnd",
            key: "DAT_SubscriptionEnd",
            sortable: false,
          },
          {
            title: "Date d'Examen",
            value: "DAT_FinalTestScheduled",
            key: "DAT_FinalTestScheduled",
            sortable: false,
          },
          {
            title: "Facturation",
            value: "TXT_Factu",
            key: "TXT_Factu",
            sortable: false,
          },
          {
            title: "Status",
            value: "TXT_SubscriptionStatus",
            key: "TXT_SubscriptionStatus",
            sortable: false,
          },
          {
            title: "Actions",
            value: "actions",
            key: "actions",
            sortable: false,
          },
        ],
      ],

      ActionAddTitle: "Prolongation de la formation",
      ActionPauseTitle:
        "Voulez-vous mettre en pause la formation de cet(te) apprenant(e) ?",
      ActionDeleteTitle:
        "Voulez-vous désinscrire cet(te) apprenant(e) de cette formation ?",
      dialogTraineeAction: false,
      dialogTitle: "",
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      traineesData: [],
      selectedTrainee: {},

      loading: false,
      totalTrainees: 0,

      currentTrainingblocSubscription: {},
      newSelectedEndDate: null,
      newSelectedBeginPauseDate: null,
      newSelectedResumeDate: null,
      newSelectedExamDate: null,
      selectedEndPause: null,
      selectedExamDate: null,

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  props: {
    status: String,
  },
  watch: {
    options: {
      handler() {
        this.getTrainingblocsubscriptions();
      },
      deep: true,
    },
  },

  methods: {
    async getTrainingblocsubscriptions() {
      const trainingblocId = parseInt(this.$route.params.id);

      this.loading = true;

      let url = `${base_url}${
        trainingblocsubscriptions_urls.get_all
      }?&timestamp=${new Date().getTime()}`;

      try {
        const response = await axios.get(url, header);
        this.traineesData = response.data.results.results.filter(
          (participant) =>
            parseInt(participant.ID_D_TrainingBloc) === trainingblocId
        );
      } catch (error) {
        console.error("Error fetching subscriptions details:", error);
      } finally {
        this.loading = false;
      }
    },

    getStatusColor(status) {
      return useChipStatusColor(status);
    },

    handleSelectedEndDateChanged(newEndDate) {
      this.newSelectedEndDate = newEndDate;
    },

    handleExamDateChanged(newExamDate) {
      this.selectedExamDate = newExamDate;
    },

    handleSelectedBeginPause(newSelectedBeginPauseDate) {
      this.selectedBeginPauseDate = newSelectedBeginPauseDate;
    },

    handleSelectedResumeDate(newSelectedResumeDate) {
      this.selectedResumeDate = newSelectedResumeDate;
    },
  },

  created() {
    this.getTrainingblocsubscriptions();
  },

  mounted() {
    document.title = "Parcours - Détails";
  },
};
</script>

<style lang="scss">
.details {
  &.trainingblocsubscriptions_actions {
    width: 95%;
    .data-table .v-table .v-data-table__td {
      width: 100%;
    }
    .v-chip.v-chip--size-default {
      min-height: 30px;
      height: unset !important;
      padding: 4px 11px;
      white-space: normal;
      text-align: center;
      line-height: 18px;
    }
    .data-table .v-table {
      .v-data-table__td {
        &:nth-child(n + 5):nth-child(-n + 10) {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 0;
          flex-wrap: wrap;
          margin-right: 0px;
          .v-input__control {
            margin-top: -3px;
          }
        }
        &:last-child {
          min-width: 140px !important;
        }
        &:nth-child(1) {
          width: 220px;
        }
        &:nth-child(2) {
          width: 200px;
        }
        &:nth-child(3) {
          max-width: 330px;
        }

        &:nth-child(4) {
          max-width: 140px;
        }
        display: flex;
        align-items: center;
      }
      .v-data-table__th:nth-child(n + 1) {
        display: flex;
        justify-content: flex-start;
        padding-top: 0;
      }
    }
  }
}

.pausecontainer {
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.label_datePicker {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-right: 1rem;
}

input[type="date"] {
  background-color: #30343b;
  padding: 15px;
  color: #ffffff;
  font-size: 22px;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  box-shadow: #505050 0px 0px 0px 1px;
  cursor: pointer;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background-color: hsl(218, 10%, 30%);
  padding: 5px;
  border-radius: 5px;
  box-shadow: #202020 0px 0px 5px -2px;
}
.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .v-btn {
    background: none;
    min-width: unset;
    box-shadow: none;
    padding: 0;
    &--disabled {
      opacity: 0.5;
    }
    .v-btn__overlay {
      background: none;
    }
  }
}
.actions_small {
  margin: 0.2rem;
  padding: 1rem;
  background-color: #34353c;
  border-radius: 100%;
  transition: 0.2s;
  &:hover {
    background-color: hsl(232deg 7% 27%);
  }
}
</style>
