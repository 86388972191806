<template>
  <div>
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Business Units</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getBusinessUnits"
            @click:append="getBusinessUnits"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>

        <v-data-table-server
          :show-current-page="true"
          :headers="headers"
          items-per-page-text="Nombre d'élements par page."
          :page="currentPage"
          :items-length="totalBusinessUnits"
          :loading="loading"
          @update:options="options = $event"
          :items="businessUnits"
        ></v-data-table-server>
      </v-card>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, bu_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "BusinessUnits",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      search: "",
      isSearchActive: false,

      totalBusinessUnits: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      sortBy: [],
      businessUnits: [],
      selectedColumn: null,

      allDataLoaded: false,

      headers: [
        {
          title: "BU Niveau 1",
          value: "TXT_BusinessUnitLevel1",
          key: "TXT_BusinessUnitLevel1",
          sortable: false,
        },
        {
          title: "BU Niveau 2",
          value: "TXT_BusinessUnitLevel2",
          key: "TXT_BusinessUnitLevel2",
          sortable: false,
        },
        {
          title: "BU Niveau 3",
          value: "TXT_BusinessUnitLevel3",
          key: "TXT_BusinessUnitLevel3",
          sortable: false,
        },
        {
          title: "BU Niveau 4",
          value: "TXT_BusinessUnitLevel4",
          key: "TXT_BusinessUnitLevel4",
          sortable: false,
        },
        {
          title: "BU Niveau 5",
          value: "TXT_BusinessUnitLevel5",
          key: "TXT_BusinessUnitLevel5",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getBusinessUnits();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${bu_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getBusinessUnits() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;

      let url = `${base_url}${bu_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.businessUnits = response.data.results.results;
        this.totalBusinessUnits = response.data.count;
      } catch (error) {
        console.error("Error fetching business units:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        bu_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}&cacheVersion=${cacheVersion}`;

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getBusinessUnits();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getBusinessUnits();
    },
  },

  mounted() {
    document.title = "Business Units";
    this.getBusinessUnits();
  },
};
</script>

<style lang="scss"></style>
