<template>
  <div class="password-change">
    <form @submit.prevent="changePassword" class="mt-n8">
      <v-container fluid style="max-width: 1440px">
        <h1 class="text-h2 text-center mb-12">Changer de mot de passe</h1>
        <v-row
          class="justify-center flex-column align-center"
          style="transform: scale(0.85)"
        >
          <!-- NEW PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg mb-10 elevation-1">
            <v-text-field
              v-model="newPassword"
              :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNew ? 'text' : 'password'"
              label="Nouveau mot de passe"
              hint="Minimum 8 caractères"
              @click:append="showNew = !showNew"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <!-- CONFIRM PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg elevation-1">
            <v-text-field
              v-model="confirmPassword"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirm ? 'text' : 'password'"
              label="Confirmer le mot de passe"
              @click:append="showConfirm = !showConfirm"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <div v-if="error" class="error-message">{{ error }}</div>

          <!-- SUBMIT -->
          <v-col class="px-7 mt-2 d-flex justify-center">
            <v-btn
              type="submit"
              :disabled="!valid"
              color="primary"
              class="d-flex pa-7 px-11 primary rounded-lg"
              :loading="loading"
            >
              Changer le mot de passe
            </v-btn>
          </v-col>
        </v-row>

        <!-- Champ caché pour le token -->
        <input type="hidden" v-model="token" />
      </v-container>
    </form>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, user_urls, header } from "@/API/config.js";

export default {
  name: "PasswordChange",
  data: () => ({
    valid: true,
    newPassword: "",
    confirmPassword: "",
    error: "",
    showNew: false,
    showConfirm: false,
    token: "",
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    loading: false,
  }),
  methods: {
    async changePassword() {
      this.loading = true;

      // Suppression de tous les espaces dans newPassword et confirmPassword
      const cleanedNewPassword = this.newPassword.replace(/\s+/g, "");
      const cleanedConfirmPassword = this.confirmPassword.replace(/\s+/g, "");

      if (cleanedNewPassword !== cleanedConfirmPassword) {
        this.error = "Les mots de passe ne correspondent pas.";
        return;
      }

      const payload = {
        password: cleanedNewPassword,
        token: this.token,
      };

      const url = `${base_url}${user_urls.edit_password}`;

      try {
        await axios.post(url, payload, header);
        this.snackbarMessage = "Mot de passe changé avec succès!";
        this.snackbarColor = "success";
        this.snackbar = true;

        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
      } catch (error) {
        console.error("Error changing password:", error);
        this.snackbarMessage =
          error.response?.data?.error || "Le token a expiré ou est invalide.";
        this.snackbarColor = "error";
        this.snackbar = true;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    document.title = "Changement de mot de passe";
    this.token = this.$route.query.token;
  },
};
</script>

<style lang="scss">
.password-change {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
