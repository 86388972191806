<template>
  <div class="questions">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Questions</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getQuestions"
            @click:append="getQuestions"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>
        <v-data-table-server
          :show-current-page="true"
          :headers="headers"
          :page="currentPage"
          :items-length="totalQuestions"
          items-per-page-text="Nombre d'élements par page."
          :items="questions"
          no-data-text="Aucune donnée"
          :loading="loading"
          @update:options="options = $event"
        >
          <template v-slot:[`item.BIT_Status`]="{ item }">
            <v-checkbox
              class="d-flex justify-center"
              v-model="item.raw.BIT_Status"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:[`item.BIT_Validate`]="{ item }">
            <v-checkbox
              class="d-flex justify-center"
              v-model="item.raw.BIT_Validate"
              disabled
            ></v-checkbox>
          </template>

          <template v-slot:[`item.TXT_Question`]="{ item }">
            <router-link :to="`/questions/${item.raw.ID_D_Question}`">{{
              item.raw.TXT_Question
            }}</router-link>
          </template>

          <template v-slot:[`item.Modules`]="{ item }">
            <div>
              <p
                color="primary"
                class="tooltip-parents__text"
                v-for="(module, index) in item.raw.Modules"
                :key="index"
              >
                {{ module.ID_D_Module__TXT_Module }}
              </p>

              <v-tooltip
                class="tooltip-parents"
                v-if="item.raw.Modules && item.raw.Modules.length > 0"
                activator="parent"
                location="end"
              >
                <template #default>
                  <ul>
                    <li
                      v-for="(module, index) in item.raw.Modules"
                      :key="index"
                    >
                      {{ module.ID_D_Module__TXT_Module }}
                    </li>
                  </ul>
                </template>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.Comments`]="{ item }">
            <div>
              <div
                v-if="item.raw.Comments && item.raw.Comments.length > 0"
                :class="{ 'comments-datatable': item.raw.Comments.length > 0 }"
              >
                {{ item.raw.Comments.length }}
                <v-tooltip
                  class="tooltip-comments"
                  activator="parent"
                  location="end"
                >
                  <template #default>
                    <ul>
                      <li
                        v-for="(
                          comment, index
                        ) in item.raw.Comments.slice().reverse()"
                        :key="index"
                      >
                        {{ comment.TXT_Comment }}
                      </li>
                    </ul>
                  </template>
                </v-tooltip>
              </div>
              <div v-else>N/A</div>
            </div>
          </template>

          <template v-slot:[`item.Tags`]="{ item }">
            <div>
              <div
                v-if="item.raw.Tags && item.raw.Tags.length > 0"
                :class="{ 'tags-datatable': item.raw.Tags.length > 0 }"
              >
                {{ item.raw.Tags.length }}
                <v-tooltip
                  class="tooltip-tags"
                  activator="parent"
                  location="end"
                >
                  <template #default>
                    <ul>
                      <li v-for="(tag, index) in item.raw.Tags" :key="index">
                        {{ tag.TXT_Tag }}
                      </li>
                    </ul>
                  </template>
                </v-tooltip>
              </div>
              <div v-else>N/A</div>
            </div>
          </template>

          <template v-slot:[`item.ActionsToPerform`]="{ item }">
            <v-icon
              icon="mdi-pencil"
              size="x-large"
              @click="openDialog(item)"
            ></v-icon>
          </template>
        </v-data-table-server>
      </v-card>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800px"
      min-width="400px"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentQuestion.TXT_Question"
            label="Question"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentQuestion.TXT_RightAnswer"
            label="Bonne réponse"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentQuestion.TXT_WrongAnswer1"
            label="Mauvaise réponse 1"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentQuestion.TXT_WrongAnswer2"
            label="Mauvaise réponse 2"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentQuestion.TXT_Explication"
            label="Explication"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
          <v-btn color="primary" text @click="updateQuestion"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, questions_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "QuestionsMain",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      totalQuestions: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      sortBy: [],
      excludedColumns: [
        "ActionsToPerform",
        "Comments",
        "Tags",
        "BIT_Status",
        "BIT_Validate",
        "Modules",
      ],

      questions: [],
      selectedColumn: null,
      dialog: false,
      dialogTitle: "",
      currentQuestion: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      headers: [
        {
          title: "Actions",
          value: "ActionsToPerform",
          key: "ActionsToPerform",
          sortable: false,
        },
        {
          title: "Coms",
          value: "Comments",
          key: "Comments",
          sortable: false,
        },
        {
          title: "Tags",
          value: "Tags",
          key: "Tags",
          sortable: false,
        },
        {
          title: "Actif ?",
          value: "BIT_Status",
          key: "BIT_Status",
          sortable: false,
        },
        {
          title: "Validé ?",
          value: "BIT_Validate",
          key: "BIT_Validate",
          sortable: false,
        },
        {
          title: "Modules",
          value: "Modules",
          key: "Modules",
          sortable: false,
        },
        {
          title: "Question",
          value: "TXT_Question",
          key: "TXT_Question",
          sortable: false,
        },
        {
          title: "Bonne Réponse",
          value: "TXT_RightAnswer",
          key: "TXT_RightAnswer",
          sortable: false,
        },
        {
          title: "Mauvaise Réponse 1",
          value: "TXT_WrongAnswer1",
          key: "TXT_WrongAnswer1",
          sortable: false,
        },
        {
          title: "Mauvaise Réponse 2",
          value: "TXT_WrongAnswer2",
          key: "TXT_WrongAnswer2",
          sortable: false,
        },
        {
          title: "Explications",
          value: "TXT_Explication",
          key: "TXT_Explication",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getQuestions();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${questions_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getQuestions() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${questions_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalQuestions = response.data.count;
        this.questions = response.data.results.results.map((question) => ({
          ...question,
          DAT_LastEdited: question.DAT_LastEdited
            ? this.formatDate(question.DAT_LastEdited)
            : "N/A",
          DAT_Created: question.DAT_Created,
          DAT_Created_Display: question.DAT_Created
            ? this.formatDate(question.DAT_Created)
            : "N/A",
        }));
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        questions_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getQuestions();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getModules();
    },

    openDialog(item) {
      const question = this.questions.find(
        (questionItem) => questionItem.ID_D_Question === item.raw.ID_D_Question
      );

      if (question) {
        this.dialogTitle = `Modifier ${question.TXT_Question}`;
        this.currentQuestion = { ...question };
        this.dialog = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    updateQuestion() {
      const questionId = this.currentQuestion.ID_D_Question;
      const url = `${base_url}${questions_urls.update(questionId)}`;

      if (
        this.currentQuestion.TXT_Question &&
        this.currentQuestion.TXT_RightAnswer &&
        this.currentQuestion.TXT_WrongAnswer1 &&
        this.currentQuestion.TXT_WrongAnswer2 &&
        this.currentQuestion.TXT_Explication
      ) {
        const updatedQuestion = {
          TXT_Question: this.currentQuestion.TXT_Question,
          TXT_RightAnswer: this.currentQuestion.TXT_RightAnswer,
          TXT_WrongAnswer1: this.currentQuestion.TXT_WrongAnswer1,
          TXT_WrongAnswer2: this.currentQuestion.TXT_WrongAnswer2,
          TXT_Explication: this.currentQuestion.TXT_Explication,
          BIT_Status: this.currentQuestion.BIT_Status,
          BIT_Validate: this.currentQuestion.BIT_Validate,

          Tags: this.currentQuestion.Tags,
          DAT_LastEdited: new Date().toISOString(), // Current date

          TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
        };

        axios
          .put(url, updatedQuestion, header)
          .then((response) => {
            this.updateQuestionInList(response.data);
            this.updateCacheBusting(); // Mise à jour du cache busting après la modification réussie

            this.questions.forEach((question) => {
              if (question.ID_D_Question === updatedQuestion.ID_D_Question) {
                question.DAT_LastEdited = this.formatDate(
                  updatedQuestion.DAT_LastEdited
                );
              }
            });

            this.getQuestions();
            this.dialog = false;

            this.snackbarMessage = `La <strong>Question</strong> a été mis à jour`;
            this.snackbarColor = "success";
            this.snackbar = true;
          })
          .catch((error) => {
            console.error(error);
            this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour de la <strong>Question</strong>`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
        this.snackbar = true;
      }
    },

    updateQuestionInList(updatedQuestion) {
      const index = this.questions.findIndex(
        (question) => question.ID_D_Question === updatedQuestion.ID_D_Question
      );
      if (index !== -1) {
        this.questions[index] = {
          ...updatedQuestion,
          DAT_Created_Display: this.formatDate(updatedQuestion.DAT_Created),
        };
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      const themeColumn = Array.from(document.querySelectorAll("th")).find(
        (th) => th.textContent === "Modules"
      );

      if (themeColumn) {
        themeColumn.classList.add("parent-column");
      }
    });
    document.title = "Questions";
    this.getQuestions();
  },
};
</script>

<style lang="scss">
.questions .data-table .v-table .v-data-table__td:nth-child(-n + 5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.questions .data-table .v-table {
  .v-data-table__th:nth-child(-n + 5) {
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
