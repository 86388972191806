<template>
  <div>
    <v-form ref="form" v-model="valid">
      <div class="d-flex gap-20">
        <div class="w-100">
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_Question"
            label="Question"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_RightAnswer"
            label="Bonne réponse"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
        </div>

        <div class="w-100">
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_WrongAnswer1"
            label="Mauvaise réponse 1"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_WrongAnswer2"
            label="Mauvaise réponse 2"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
        </div>
      </div>

      <div class="d-flex gap-20">
        <v-textarea
          rows="4"
          auto-grow
          v-model="form.TXT_Explication"
          label="Explication"
          variant="solo"
          class="form-input required"
          :rules="[rules.required]"
        />
        <div class="">
          <v-textarea
            rows="1"
            auto-grow
            v-model="form.TXT_Source"
            label="Source"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="1"
            auto-grow
            v-model="form.TXT_ExpertName"
            label="Nom de l'expert"
            variant="solo"
            class="form-input"
          />
        </div>
      </div>

      <v-checkbox
        class="text-h6 fat-checkbox"
        v-model="form.BIT_Status"
        label="Actif ?"
        hide-details
      />
      <v-checkbox
        class="text-h6 fat-checkbox"
        v-model="form.BIT_Validate"
        label="Validé ?"
        hide-details
      />
      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter la Question
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, questions_urls, header } from "@/API/config.js";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      form: {
        TXT_Question: "",
        TXT_ExpertName: "",
        BIT_Status: "false",
        BIT_Validate: "false",
        TXT_Source: "",
        TXT_Explication: "",
        TXT_RightAnswer: "",
        TXT_WrongAnswer1: "",
        TXT_WrongAnswer2: "",
        TXT_CreatedBy: this.$store.state.auth.id, // Current user ID from Vuex store
        TXT_LastEditedBy: this.$store.state.auth.id,
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async submitForm() {
      const url = `${base_url}${questions_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;

      if (this.form.BIT_Status === "") {
        this.form.BIT_Status = "false";
      }

      if (this.form.BIT_Validate === "") {
        this.form.BIT_Validate = "false";
      }

      if (!this.form.TXT_ExpertName) {
        this.form.TXT_ExpertName = null;
      }

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Une nouvelle <strong>Question</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout d'une <strong>Question</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_Question: "",
        TXT_ExpertName: "",
        BIT_Status: "false",
        BIT_Validate: "false",
        TXT_Source: "",
        TXT_Explication: "",
        TXT_RightAnswer: "",
        TXT_WrongAnswer1: "",
        TXT_WrongAnswer2: "",
        TXT_CreatedBy: this.$store.state.auth.id, // Current user ID from Vuex store
        TXT_LastEditedBy: this.$store.state.auth.id,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style lang="scss">
.fat-checkbox {
  label {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
