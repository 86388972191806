<template>
  <div class="details management">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Management</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div>
                      {{ management[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>
            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="d-flex align-center justify-space-between w-100">
                    <div class="details-table-infos-content">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="checkbox">
                      <v-checkbox
                        v-model="management[key]"
                        @click="checkboxUpdate(key, !management[key])"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </v-list-item>

              <div class="businessunits v-list-item__content px-10 py-3">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="mb-2">
                    <strong>BusinessUnits :</strong>
                  </h4>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog('BusinessUnit')"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>

                <div v-if="management && management.BusinessUnit">
                  <ul
                    v-for="unit in management.BusinessUnit"
                    :key="unit.ID_D_BusinessUnit"
                  >
                    <li
                      class="businessunits"
                      v-for="key in keyOrder3"
                      :key="key"
                    >
                      {{ unit[key] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="px-10 py-3 mt-8 d-flex align-center">
              <h2 class="text-h6 font-weight-bold">
                Apprenant·es associé·es :
              </h2>
              <v-btn
                icon
                class="details-table-infos__edit ml-2"
                @click="openUpdateDialog('Trainees')"
              >
                <v-icon icon="mdi-pencil" size="x-large"></v-icon>
              </v-btn>
            </div>
            <v-data-table
              :headers="TraineesHeaders"
              :items="management.Trainees"
              class="w-100 related-data-table mx-10"
              color="table"
            >
              <template v-slot:[`item.TXT_Title`]="{ item }">
                <router-link :to="`/trainee/${item.raw.ID_D_Trainee}`">{{
                  item.raw.TXT_Module
                }}</router-link>
              </template>
            </v-data-table>
          </v-list>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="
              fieldToUpdate !== 'BusinessUnit' && fieldToUpdate !== 'Trainees'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>

          <v-select
            v-if="fieldToUpdate === 'BusinessUnit'"
            v-model="newFieldValue"
            :items="formattedBusinessUnits"
            item-value="value"
            item-text="title"
            label="Sélectionner une Business Unit"
            :loading="isLoading"
          >
            <template #prepend-item>
              <v-text-field
                v-model="search"
                label="Rechercher dans la liste..."
                variant="underlined"
                single-line
                color="primary"
                class="px-6"
              ></v-text-field>
            </template>
          </v-select>

          <v-select
            v-if="fieldToUpdate === 'Trainees'"
            v-model="newFieldValue"
            :items="formattedTrainees"
            item-value="value"
            item-text="text"
            label="Sélectionner des apprenant·es"
            multiple
            :loading="isLoading"
          >
            <template #prepend-item>
              <v-text-field
                v-model="search"
                label="Rechercher dans la liste..."
                variant="underlined"
                single-line
                color="primary"
                class="px-6"
              ></v-text-field>
            </template>
            <template #selection="{ index, item, selected }">
              <v-chip
                v-if="index === 0"
                :key="`selected-${item.value}`"
                :input-value="selected"
              >
                {{ newFieldValue.length }} sélectionnés
              </v-chip>
            </template>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  managements_urls,
  bu_urls,
  trainees_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ManangementSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      keyTextMap: {
        TXT_LastName: "Nom",
        TXT_FirstName: "Prénom",
        TXT_Email: "Email",
        TXT_PhoneNumber: "Numéro de téléphone",
        Trainees: "Apprenant·es associé·es",

        BIT_ReportReceiver: "Réception de reporting",
        BIT_IsTrainingDepartment: "Services de formation",
        BIT_ReportReceiverChildBU: "Visibilité sur les BU enfants",
        ID_D_BusinessUnit: "Business Unit",
      },
      keyOrder1: [
        "TXT_LastName",
        "TXT_FirstName",
        "TXT_Email",
        "TXT_PhoneNumber",
      ],
      keyOrder2: [
        "BIT_ReportReceiver",
        "BIT_IsTrainingDepartment",
        "BIT_ReportReceiverChildBU",
      ],

      keyOrder3: [
        "TXT_BusinessUnitLevel1",
        "TXT_BusinessUnitLevel2",
        "TXT_BusinessUnitLevel3",
        "TXT_BusinessUnitLevel4",
        "TXT_BusinessUnitLevel5",
      ],

      TraineesHeaders: [
        {
          title: "Nom",
          value: "TXT_LastName",
          key: "TXT_LastName",
        },
        {
          title: "Prénom",
          value: "TXT_FirstName",
          key: "TXT_FirstName",
        },
        {
          title: "E-Mail",
          value: "TXT_Email",
          key: "TXT_Email",
        },
      ],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      management: [],
      BusinessUnits: [],
      Trainees: [],
      search: "",
      isLoading: false,

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    getManagementDetails() {
      const managementId = this.$route.params.id;
      const url = `${base_url}${managements_urls.get_details(managementId)}`;

      axios
        .get(url, header)
        .then((response) => {
          this.management = {
            ...response.data,
          };
        })
        .catch((error) => {
          console.error("Error fetching management details:", error);
        });
    },

    getBusinessUnits() {
      const url = `${base_url}${bu_urls.get_all}`;
      this.isLoading = true;
      axios
        .get(url, header)
        .then((response) => {
          this.BusinessUnits = response.data.results.results;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching Business units:", error);
          this.isLoading = false;
        });
    },

    getTrainees() {
      const url = `${base_url}${trainees_urls.get_all}?page=1&page_size=100000`;
      this.isLoading = true;
      axios
        .get(url, header)
        .then((response) => {
          this.Trainees = response.data.results.results;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching Trainees:", error);
        });
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "BusinessUnit") {
        this.getBusinessUnits();
        this.newFieldValue = this.management.ID_D_BusinessUnit;
        this.dialogTitle = "Modifier la Business Unit";
      } else if (key === "Trainees") {
        this.getTrainees();
        this.newFieldValue = this.management[key].map(
          (trainee) => trainee.ID_D_Trainee
        );
      } else {
        this.newFieldValue = this.management[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (this.newFieldValue !== null && this.newFieldValue !== undefined) {
        if (this.fieldToUpdate === "BusinessUnit") {
          this.updateManagement("ID_D_BusinessUnit", this.newFieldValue);
        } else if (this.fieldToUpdate === "Trainee") {
          const newTrainees = this.Trainees.filter((management) =>
            this.newFieldValue.includes(management.ID_D_Trainee)
          );
          this.updateTheme(this.fieldToUpdate, newTrainees);
        } else {
          this.updateManagement(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },

    checkboxUpdate(key, newValue) {
      this.updateManagement(key, newValue);
    },
    updateManagement(field, newValue) {
      // Update the management on the server
      const managementId = this.$route.params.id;
      const url = `${base_url}${managements_urls.update(managementId)}`;

      const updatedData = {
        TXT_LastName: this.management.TXT_LastName,
        TXT_FirstName: this.management.TXT_FirstName,
        TXT_Email: this.management.TXT_Email,
        TXT_PhoneNumber: this.management.TXT_PhoneNumber,
        BIT_ReportReceiver: this.management.BIT_ReportReceiver,
        BIT_IsTrainingDepartment: this.management.BIT_IsTrainingDepartment,
        BIT_ReportReceiverChildBU: this.management.BIT_ReportReceiverChildBU,
        [field]: newValue,
      };

      if (field !== "Trainees") {
        updatedData.Trainees = this.management.Trainees.map(
          (trainee) => trainee.ID_D_Trainee
        );
      }

      if (field === "Trainees") {
        updatedData.Trainees = this.newFieldValue;
      }

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.management = response.data;

          // Get the latest management details from the server.
          this.getManagementDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    formatBusinessUnitTitle(levels) {
      const title = levels
        .filter(Boolean)
        .map((item, index) => `${index + 1}.${item}`)
        .join(" - ");
      return title.length > 75 ? title.substring(0, 75) + "..." : title;
    },
  },

  computed: {
    formattedTrainees() {
      if (!this.search) {
        return this.Trainees.map((trainee) => ({
          title:
            trainee.TXT_Email.length > 75
              ? trainee.TXT_Email.substring(0, 75) + "..."
              : trainee.TXT_Email,
          value: trainee.ID_D_Trainee,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.Trainees.filter((trainee) => {
        const traineeLowerCase = trainee.TXT_Email.toLowerCase();
        return searchTerms.every((term) => traineeLowerCase.includes(term));
      }).map((trainee) => ({
        title:
          trainee.TXT_Email.length > 75
            ? trainee.TXT_Email.substring(0, 75) + "..."
            : trainee.TXT_Email,
        value: trainee.ID_D_Trainee,
      }));
    },

    formattedBusinessUnits() {
      if (!this.BusinessUnits || this.BusinessUnits.length === 0) {
        return [];
      }
      if (!this.search) {
        return this.BusinessUnits.map((bu) => ({
          value: bu.ID_D_BusinessUnit,
          title: this.formatBusinessUnitTitle([
            bu.TXT_BusinessUnitLevel1,
            bu.TXT_BusinessUnitLevel2,
            bu.TXT_BusinessUnitLevel3,
            bu.TXT_BusinessUnitLevel4,
            bu.TXT_BusinessUnitLevel5,
          ]),
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.BusinessUnits.filter((bu) => {
        const buLowerCase = [
          bu.TXT_BusinessUnitLevel1,
          bu.TXT_BusinessUnitLevel2,
          bu.TXT_BusinessUnitLevel3,
          bu.TXT_BusinessUnitLevel4,
          bu.TXT_BusinessUnitLevel5,
        ]
          .filter(Boolean)
          .join(" - ")
          .toLowerCase();
        return searchTerms.every((term) => buLowerCase.includes(term));
      }).map((bu) => ({
        value: bu.ID_D_BusinessUnit,
        title: this.formatBusinessUnitTitle([
          bu.TXT_BusinessUnitLevel1,
          bu.TXT_BusinessUnitLevel2,
          bu.TXT_BusinessUnitLevel3,
          bu.TXT_BusinessUnitLevel4,
          bu.TXT_BusinessUnitLevel5,
        ]),
      }));
    },
  },

  created() {
    this.getManagementDetails();
  },

  mounted() {
    document.title = "Management - Détails";
  },
};
</script>

<style lang="scss">
.details.management .data-table .v-table .v-data-table__td {
  width: 100%;
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
