<template>
  <div class="subthemes">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Sous-themes</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getSubThemes"
            @click:append="getSubThemes"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>

        <v-data-table-server
          :headers="headers"
          :show-current-page="true"
          items-per-page-text="Nombre d'élements par page."
          :page="currentPage"
          :items-length="totalSubThemes"
          :items="subthemes"
          no-data-text="Aucune donnée"
          :loading="loading"
          @update:options="options = $event"
        >
          <template v-slot:[`item.TXT_SubTheme`]="{ item }">
            <router-link :to="`/subthemes/${item.raw.ID_D_SubTheme}`">{{
              item.raw.TXT_SubTheme
            }}</router-link>
          </template>

          <template v-slot:[`item.Themes`]="{ item }">
            <div>
              <p
                color="primary"
                class="tooltip-parents__text"
                v-for="(theme, index) in item.raw.Themes"
                :key="index"
              >
                {{ theme.ID_D_Theme__TXT_Theme }}
              </p>

              <v-tooltip
                class="tooltip-parents"
                v-if="item.raw.Themes && item.raw.Themes.length > 0"
                activator="parent"
                location="end"
              >
                <template #default>
                  <ul>
                    <li v-for="(theme, index) in item.raw.Themes" :key="index">
                      {{ theme.ID_D_Theme__TXT_Theme }}
                    </li>
                  </ul>
                </template>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.Comments`]="{ item }">
            <div>
              <div
                v-if="item.raw.Comments && item.raw.Comments.length > 0"
                :class="{ 'comments-datatable': item.raw.Comments.length > 0 }"
              >
                {{ item.raw.Comments.length }}
                <v-tooltip
                  class="tooltip-comments"
                  activator="parent"
                  location="end"
                >
                  <template #default>
                    <ul>
                      <li
                        v-for="(
                          comment, index
                        ) in item.raw.Comments.slice().reverse()"
                        :key="index"
                      >
                        {{ comment.TXT_Comment }}
                      </li>
                    </ul>
                  </template>
                </v-tooltip>
              </div>
              <div v-else>N/A</div>
            </div>
          </template>

          <template v-slot:[`item.ActionsToPerform`]="{ item }">
            <v-icon
              icon="mdi-pencil"
              size="x-large"
              @click="openDialog(item)"
            ></v-icon>
          </template>

          <template v-slot:[`item.Modules`]="{ item }">
            {{ item.raw.Modules.length }}
          </template>

          <template v-slot:[`item.TXT_Ref`]="{ item }">
            {{ item.raw.TXT_Ref ? item.raw.TXT_Ref : "N/A" }}
          </template>
        </v-data-table-server>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800px"
      min-width="400px"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentSubTheme.TXT_SubTheme"
            label="Thème"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentSubTheme.TXT_Description"
            label="Description"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentSubTheme.TXT_LearningObjectif"
            label="Objectif pédagogique"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
          <v-btn color="primary" text @click="updateSubTheme"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, subthemes_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "SubThemesMain",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      totalSubThemes: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      excludedColumns: [
        "ActionsToPerform",
        "Comments",
        "Themes",
        "Modules",
        "DAT_Created_Display",
        "DAT_LastEdited",
      ],

      subthemes: [],
      selectedColumn: null,
      dialog: false,
      dialogTitle: "",
      currentSubTheme: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      headers: [
        {
          title: "Actions",
          value: "ActionsToPerform",
          key: "ActionsToPerform",
          sortable: false,
        },
        {
          title: "Coms",
          value: "Comments",
          key: "Comments",
          sortable: false,
        },
        {
          title: "Ref",
          value: "TXT_Ref",
          key: "TXT_Ref",
          sortable: false,
        },
        {
          title: "Themes",
          value: "Themes",
          key: "Themes",
          sortable: false,
        },
        {
          title: "Code contenu",
          value: "TXT_Code",
          key: "TXT_Code",
          sortable: false,
        },
        {
          title: "Sous-thème",
          value: "TXT_SubTheme",
          key: "TXT_SubTheme",
          sortable: false,
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
          sortable: false,
        },
        {
          title: "Objectif pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
          sortable: false,
        },
        {
          title: "Modules",
          value: "Modules",
          key: "Modules",
          sortable: false,
        },
        {
          title: "Création",
          value: "DAT_Created_Display",
          key: "DAT_Created_Display",
          sortable: false,
        },
        {
          title: "Modification",
          value: "DAT_LastEdited",
          key: "DAT_LastEdited",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getSubThemes();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${subthemes_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getSubThemes() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${subthemes_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalSubThemes = response.data.count;
        this.subthemes = response.data.results.results.map((subtheme) => ({
          ...subtheme,
          DAT_LastEdited: subtheme.DAT_LastEdited
            ? this.formatDate(subtheme.DAT_LastEdited)
            : "N/A",
          DAT_Created: subtheme.DAT_Created,
          DAT_Created_Display: subtheme.DAT_Created
            ? this.formatDate(subtheme.DAT_Created)
            : "N/A",
        }));
      } catch (error) {
        console.error("Error fetching subthemes:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        subthemes_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getSubThemes();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getSubThemes();
    },

    openDialog(item) {
      const subtheme = this.subthemes.find(
        (subthemeItem) => subthemeItem.ID_D_SubTheme === item.raw.ID_D_SubTheme
      );

      if (subtheme) {
        this.dialogTitle = `Modifier ${subtheme.TXT_SubTheme}`;
        this.currentSubTheme = { ...subtheme };
        this.dialog = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    updateSubTheme() {
      const subthemeId = this.currentSubTheme.ID_D_SubTheme;
      const url = `${base_url}${subthemes_urls.update(subthemeId)}`;

      if (this.currentSubTheme.TXT_SubTheme) {
        const updatedSubTheme = {
          ...this.currentSubTheme,
          DAT_LastEdited: new Date().toISOString(), // Current date

          TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
        };

        // Envoyer la requête de mise à jour au serveur
        axios
          .put(url, updatedSubTheme, header)
          .then((response) => {
            // Mettre à jour le thème dans la liste
            this.updateSubThemeInList(response.data);
            this.updateCacheBusting(); // Mise à jour du cache busting après la modification réussie

            this.subthemes.forEach((subtheme) => {
              if (subtheme.ID_D_SubTheme === updatedSubTheme.ID_D_SubTheme) {
                subtheme.DAT_LastEdited = this.formatDate(
                  updatedSubTheme.DAT_LastEdited
                );
              }
            });

            this.getSubThemes();
            this.dialog = false;

            this.snackbarMessage = `Le <strong>Sous-Thème</strong> a été mis à jour`;
            this.snackbarColor = "success";
            this.snackbar = true;
          })
          .catch((error) => {
            console.error(error);

            this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Sous-Thème</strong>`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
        this.dialog = false;
      }
    },

    updateSubThemeInList(updatedSubTheme) {
      const index = this.subthemes.findIndex(
        (subtheme) => subtheme.ID_D_SubTheme === updatedSubTheme.ID_D_SubTheme
      );
      if (index !== -1) {
        this.subthemes[index] = {
          ...updatedSubTheme,
          DAT_Created_Display: this.formatDate(updatedSubTheme.DAT_Created),
        };
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      const themeColumn = Array.from(document.querySelectorAll("th")).find(
        (th) => th.textContent === "Themes"
      );

      if (themeColumn) {
        themeColumn.classList.add("parent-column");
      }
    });
    document.title = "Sous-Thèmes";
    this.getSubThemes();
  },
};
</script>

<style lang="scss">
.subthemes .data-table .v-table .v-data-table__td:nth-child(-n + 3),
.subthemes .data-table .v-table .v-data-table__td:nth-child(5) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.subthemes .data-table .v-table .v-data-table__td:nth-child(5) {
  width: 125px;
  white-space: nowrap;
}

.subthemes .data-table .v-table {
  .v-data-table__td:nth-last-child(-n + 3) {
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      justify-content: center;
    }
  }
  .v-data-table__th:nth-last-child(-n + 3),
  .v-data-table__th:nth-child(-n + 3) {
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
