<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-select
        :items="formattedModules"
        item-text="title"
        item-value="value"
        label="Modules"
        v-model="selectedModules"
        multiple
        class="form-input"
        :loading="isLoading"
        no-data-text="Aucune donnée"
      >
        <template #prepend-item>
          <v-text-field
            v-model="search"
            label="Rechercher dans la liste..."
            variant="underlined"
            single-line
            color="primary"
            class="px-6"
          ></v-text-field>
        </template>
        <template #selection="{ index, item, selected }">
          <v-chip
            v-if="index === 0"
            :key="`selected-${item.value}`"
            :input-value="selected"
          >
            {{ selectedModules.length }} sélectionnés
          </v-chip>
        </template>
      </v-select>

      <v-textarea
        rows="1"
        auto-grow
        v-model="form.TXT_SubTheme"
        label="Sous-Thème"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_Description"
        label="Description"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_LearningObjectif"
        label="Objectifs Pédagogiques"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter le Sous-Thème
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  base_url,
  subthemes_urls,
  modules_urls,
  header,
} from "@/API/config.js";

export default {
  data() {
    return {
      search: "",
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      Modules: [],
      selectedModules: [],
      isLoading: false,

      form: {
        TXT_SubTheme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
        TXT_Ref: null,
        selectedModules: [],
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async getModules() {
      const url = `${base_url}${modules_urls.get_all}?page=1&page_size=100000`;
      this.isLoading = true;
      try {
        const response = await axios.get(url, header);
        this.Modules = response.data.results.results;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async submitForm() {
      const url = `${base_url}${subthemes_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;
      this.form.Modules = this.selectedModules;

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Un nouveau <strong>Sous-Thème</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du <strong>Sous-Thème</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_SubTheme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
        TXT_Ref: null,
        selectedModules: [],
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },

  computed: {
    formattedModules() {
      if (!this.search) {
        return this.Modules.map((module) => ({
          title:
            module.TXT_Module.length > 60
              ? module.TXT_Module.substring(0, 60) + "..."
              : module.TXT_Module,
          value: module.ID_D_Module,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.Modules.filter((module) => {
        const moduleLowerCase = module.TXT_Module.toLowerCase();
        return searchTerms.every((term) => moduleLowerCase.includes(term));
      }).map((module) => ({
        title:
          module.TXT_Module.length > 60
            ? module.TXT_Module.substring(0, 60) + "..."
            : module.TXT_Module,
        value: module.ID_D_Module,
      }));
    },
  },

  created() {
    this.getModules();
  },
};
</script>
