<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-select
        :items="formattedSubThemes"
        item-text="title"
        item-value="value"
        label="Sous-Thèmes"
        v-model="selectedSubThemes"
        multiple
        class="form-input"
        :loading="isLoading"
        no-data-text="Aucune donnée"
      >
        <template #prepend-item>
          <v-text-field
            v-model="search"
            label="Rechercher dans la liste..."
            variant="underlined"
            single-line
            color="primary"
            class="px-6"
          ></v-text-field>
        </template>
        <template #selection="{ index, item, selected }">
          <v-chip
            v-if="index === 0"
            :key="`selected-${item.value}`"
            :input-value="selected"
          >
            {{ selectedSubThemes.length }} sélectionnés
          </v-chip>
        </template>
      </v-select>

      <v-textarea
        rows="1"
        auto-grow
        v-model="form.TXT_Theme"
        label="Thème"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_Description"
        label="Description"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_LearningObjectif"
        label="Objectifs Pédagogiques"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter le Thème
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, themes_urls, subthemes_urls, header } from "@/API/config.js";

export default {
  data() {
    return {
      search: "",
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      SubThemes: [],
      selectedSubThemes: [],
      isLoading: false,

      form: {
        TXT_Theme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
        selectedSubThemes: [],
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async getSubThemes() {
      const url = `${base_url}${subthemes_urls.get_all}?page=1&page_size=100000`;
      this.isLoading = true;
      try {
        const response = await axios.get(url, header);
        this.SubThemes = response.data.results.results;
      } catch (error) {
        console.error("Error fetching SubThemes:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async submitForm() {
      const url = `${base_url}${themes_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;
      this.form.SubThemes = this.selectedSubThemes;

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Un nouveau <strong>Thème</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du <strong>Thème</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_Theme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
        selectedSubThemes: [],
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },

  computed: {
    formattedSubThemes() {
      if (!this.search) {
        return this.SubThemes.map((subTheme) => ({
          title:
            subTheme.TXT_SubTheme.length > 60
              ? subTheme.TXT_SubTheme.substring(0, 60) + "..."
              : subTheme.TXT_SubTheme,
          value: subTheme.ID_D_SubTheme,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.SubThemes.filter((subTheme) => {
        const subThemeLowerCase = subTheme.TXT_SubTheme.toLowerCase();
        return searchTerms.every((term) => subThemeLowerCase.includes(term));
      }).map((subTheme) => ({
        title:
          subTheme.TXT_SubTheme.length > 60
            ? subTheme.TXT_SubTheme.substring(0, 60) + "..."
            : subTheme.TXT_SubTheme,
        value: subTheme.ID_D_SubTheme,
      }));
    },
  },

  created() {
    this.getSubThemes();
  },
};
</script>
