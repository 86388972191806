<template>
  <div class="modules">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Modules</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getModules"
            @click:append="getModules"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>
        <v-data-table-server
          :show-current-page="true"
          items-per-page-text="Nombre d'élements par page."
          :headers="headers"
          :page="currentPage"
          :items-length="totalModules"
          :items="modules"
          no-data-text="Aucune donnée"
          :loading="loading"
          loading-text="Loading... Please wait"
          @update:options="options = $event"
        >
          <template v-slot:[`item.TXT_Module`]="{ item }">
            <router-link :to="`/modules/${item.raw.ID_D_Module}`">{{
              item.raw.TXT_Module
            }}</router-link>
          </template>

          <template v-slot:[`item.SubThemes`]="{ item }">
            <div>
              <p
                color="primary"
                class="tooltip-parents__text"
                v-for="(subtheme, index) in item.raw.SubThemes"
                :key="index"
              >
                {{ subtheme.ID_D_SubTheme__TXT_SubTheme }}
              </p>

              <v-tooltip
                class="tooltip-parents"
                v-if="item.raw.SubThemes && item.raw.SubThemes.length > 0"
                activator="parent"
                location="end"
              >
                <template #default>
                  <ul>
                    <li
                      v-for="(subtheme, index) in item.raw.SubThemes"
                      :key="index"
                    >
                      {{ subtheme.ID_D_SubTheme__TXT_SubTheme }}
                    </li>
                  </ul>
                </template>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.Comments`]="{ item }">
            <div>
              <div
                v-if="item.raw.Comments && item.raw.Comments.length > 0"
                :class="{ 'comments-datatable': item.raw.Comments.length > 0 }"
              >
                {{ item.raw.Comments.length }}
                <v-tooltip
                  class="tooltip-comments"
                  activator="parent"
                  location="end"
                >
                  <template #default>
                    <ul>
                      <li
                        v-for="(
                          comment, index
                        ) in item.raw.Comments.slice().reverse()"
                        :key="index"
                      >
                        {{ comment.TXT_Comment }}
                      </li>
                    </ul>
                  </template>
                </v-tooltip>
              </div>
              <div v-else>N/A</div>
            </div>
          </template>

          <template v-slot:[`item.ActionsToPerform`]="{ item }">
            <v-icon
              icon="mdi-pencil"
              size="x-large"
              @click="openDialog(item)"
            ></v-icon>
          </template>

          <template v-slot:[`item.Questions`]="{ item }">
            {{ item.raw.Questions.length }}
          </template>

          <template v-slot:[`item.TXT_Ref`]="{ item }">
            {{ item.raw.TXT_Ref ? item.raw.TXT_Ref : "N/A" }}
          </template>
        </v-data-table-server>
      </v-card>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800px"
      min-width="400px"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentModule.TXT_Module"
            label="Module"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentModule.TXT_Description"
            label="Description"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentModule.TXT_LearningObjectif"
            label="Objectif pédagogique"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
          <v-btn color="primary" text @click="updateModule">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, modules_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ModulesMain",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      totalModules: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      sortBy: [],
      excludedColumns: [
        "ActionsToPerform",
        "Comments",
        "SubThemes",
        "Questions",
        "DAT_Created_Display",
        "DAT_LastEdited",
      ],

      modules: [],
      selectedColumn: null,
      dialog: false,
      dialogTitle: "",
      currentModule: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      headers: [
        {
          title: "Actions",
          value: "ActionsToPerform",
          key: "ActionsToPerform",
          sortable: false,
        },
        {
          title: "Coms",
          value: "Comments",
          key: "Comments",
          sortable: false,
        },
        {
          title: "Ref",
          value: "TXT_Ref",
          key: "TXT_Ref",
          sortable: false,
        },
        {
          title: "Sous-Themes",
          value: "SubThemes",
          key: "SubThemes",
          sortable: false,
        },
        {
          title: "Module",
          value: "TXT_Module",
          key: "TXT_Module",
          sortable: false,
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
          sortable: false,
        },
        {
          title: "Objectif Pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
          sortable: false,
        },
        {
          title: "Questions",
          value: "Questions",
          key: "Questions",
          sortable: false,
        },
        {
          title: "Création",
          value: "DAT_Created_Display",
          key: "DAT_Created_Display",
          sortable: false,
        },
        {
          title: "Modification",
          value: "DAT_LastEdited",
          key: "DAT_LastEdited",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getModules();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${modules_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getModules() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${modules_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalModules = response.data.count;
        this.modules = response.data.results.results.map((module) => ({
          ...module,
          DAT_LastEdited: module.DAT_LastEdited
            ? this.formatDate(module.DAT_LastEdited)
            : "N/A",
          DAT_Created: module.DAT_Created,
          DAT_Created_Display: module.DAT_Created
            ? this.formatDate(module.DAT_Created)
            : "N/A",
        }));
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        modules_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}&cacheVersion=${cacheVersion}`;

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getModules();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getModules();
    },

    openDialog(item) {
      const module = this.modules.find(
        (moduleItem) => moduleItem.ID_D_Module === item.raw.ID_D_Module
      );

      if (module) {
        this.dialogTitle = `Modifier ${module.TXT_Module}`;
        this.currentModule = { ...module };
        this.dialog = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    updateModule() {
      const moduleId = this.currentModule.ID_D_Module;
      const url = `${base_url}${modules_urls.update(moduleId)}`;

      const updatedModule = {
        TXT_Module: this.currentModule.TXT_Module,
        TXT_Description: this.currentModule.TXT_Description,
        TXT_LearningObjectif: this.currentModule.TXT_LearningObjectif,
        Questions: this.currentModule.Questions,

        DAT_LastEdited: new Date().toISOString(), // Current date
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
      };

      axios
        .put(url, updatedModule, header)
        .then((response) => {
          this.updateModuleInList(response.data);
          this.updateCacheBusting(); // Mise à jour du cache busting après la modification réussie

          this.modules.forEach((module) => {
            if (module.ID_D_Module === updatedModule.ID_D_Module) {
              module.DAT_LastEdited = this.formatDate(
                updatedModule.DAT_LastEdited
              );
            }
          });

          this.getModules();
          this.dialog = false;

          this.snackbarMessage = `Le <strong>Module</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Module</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
      this.dialog = false;
    },

    updateModuleInList(updatedModule) {
      const index = this.modules.findIndex(
        (module) => module.ID_D_Module === updatedModule.ID_D_Module
      );
      if (index !== -1) {
        this.modules[index] = {
          ...updatedModule,
          DAT_Created_Display: this.formatDate(updatedModule.DAT_Created),
        };
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      const themeColumn = Array.from(document.querySelectorAll("th")).find(
        (th) => th.textContent === "Sous-Themes"
      );

      if (themeColumn) {
        themeColumn.classList.add("parent-column");
      }
    });
    document.title = "Modules";
    this.getModules();
  },
};
</script>

<style lang="scss">
.modules .data-table .v-table .v-data-table__td:nth-child(-n + 3) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.modules .data-table .v-table {
  .v-data-table__td:nth-last-child(-n + 3) {
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      justify-content: center;
    }
  }
  .v-data-table__th:nth-last-child(-n + 3),
  .v-data-table__th:nth-child(-n + 3) {
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
